import { useCallback, useEffect, useMemo, useState } from 'react';
import { utils as gebUtils } from 'geb.js';
import useGeb from './useGeb';
import { BigNumber, ethers } from 'ethers';
import { useStoreState } from '../store';

export default function useTokenomicsInfo() {
  const { state, isLoading } = useTokenomics();

  const {
    surplusBufferBar,
    flxBurned,
    raiSurplus,
    raiSurplusPerWeek,
    flxAirDrop,
    raiPrice,
    flxLeft,
  } = state;

  const stats = useMemo(
    () => [
      {
        name: 'FLX Burned',
        value: flxBurned,
        icon: `${process.env.PUBLIC_URL + '/img/flx-burned.svg'}`,
        tip: '',
        isHtml: false,
        width: 'col3',
      },
      {
        name: 'FLX Airdropped',
        value: flxAirDrop,
        icon: `${process.env.PUBLIC_URL + '/img/flx-airdropped.svg'}`,
        tip: '',
        isHtml: false,
        width: 'col3',
      },
      {
        name: 'FLX in Treasury',
        value: flxLeft,
        icon: `${process.env.PUBLIC_URL + '/img/flx-airdropped.svg'}`,
        tip: '',
        isHtml: false,
        width: 'col3',
      },
      {
        name: 'RAI Surplus',
        value: raiSurplus,
        icon: `${process.env.PUBLIC_URL + '/img/stat2.svg'}`,
        tip: '',
        isHtml: false,
        width: 'col50',
      },
      {
        name: 'RAI Weekly Surplus Amount',
        value: raiSurplusPerWeek,
        icon: `${process.env.PUBLIC_URL + '/img/stat2.svg'}`,
        tip: '',
        isHtml: false,
        width: 'col50',
      },
    ],
    [flxAirDrop, flxBurned, flxLeft, raiSurplus, raiSurplusPerWeek]
  );

  return {
    stats,
    surplusBufferBar,
    flxBurned,
    raiSurplus,
    raiSurplusPerWeek,
    flxAirDrop,
    raiPrice,
    isLoading,
  };
}

export function useTokenomics() {
  const geb = useGeb();
  const [isLoading, setIsLoading] = useState(true);

  const [state, setState] = useState({
    surplusBufferBar: '',
    flxBurned: '',
    raiSurplus: '',
    raiSurplusPerWeek: '',
    flxAirDrop: '',
    raiPrice: '',
    flxLeft: '',
  });

  const { statisticsModel: statisticsState } = useStoreState((state) => state);

  const { stats } = statisticsState;

  const gebValuesCallback = useCallback(
    ([
      accountingEngineBalance,
      surplusBuffer,
      totalSupply,
      totalQueuedDebt,
      collateralTypes,
      globalDebt,
      flxAirdropped,
    ]: [
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      { stabilityFee: BigNumber; updateTime: BigNumber },
      BigNumber,
      BigNumber
    ]) => {
      const surplusBufferBar = accountingEngineBalance
        .mul(gebUtils.WAD)
        .div(surplusBuffer);
      const flxBurned = gebUtils.WAD.mul(1e6).sub(totalSupply);
      const raiSurplus = accountingEngineBalance
        .sub(totalQueuedDebt)
        .div(gebUtils.RAY);
      const raiSurplusPerWeek = collateralTypes.stabilityFee
        .sub(gebUtils.RAY)
        .mul(globalDebt)
        .div(gebUtils.RAD)
        .div('1000000000')
        .mul(3600 * 24 * 7);

      const flxAirDrop = gebUtils.WAD.mul(350000).sub(flxAirdropped);

      setState({
        surplusBufferBar: ethers.utils.formatEther(surplusBufferBar),
        flxBurned: ethers.utils.formatEther(flxBurned),
        raiSurplus: ethers.utils.formatEther(raiSurplus),
        raiSurplusPerWeek: ethers.utils.formatEther(raiSurplusPerWeek),
        flxAirDrop: ethers.utils.formatEther(flxAirDrop),
        flxLeft: ethers.utils.formatEther(flxAirdropped),
        raiPrice: stats ? stats?.systemState.currentRedemptionPrice.value : '',
      });
      setIsLoading(false);
    },
    [stats]
  );

  useEffect(() => {
    if (!geb) return;
    geb
      .multiCall([
        geb.contracts.safeEngine.coinBalance(
          geb.contracts.accountingEngine.address,
          true
        ),
        geb.contracts.accountingEngine.surplusBuffer(true),
        geb.contracts.protocolToken.totalSupply(true),
        geb.contracts.accountingEngine.totalQueuedDebt(true),
        geb.contracts.taxCollector.collateralTypes(gebUtils.ETH_A, true),
        geb.contracts.safeEngine.globalDebt(true),
        geb.contracts.protocolToken.balanceOf(
          '0xa3c1f84fcbedc93aec401120206b48bea951d8d0',
          true
        ),
      ])
      .then(gebValuesCallback)
      .catch((e) => console.log(e));
  }, [geb, gebValuesCallback]);

  return useMemo(() => {
    return { state, isLoading };
  }, [isLoading, state]);
}
