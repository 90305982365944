export const statisticsQuery = () => `{

  systemState(id: "current") {
   
    currentRedemptionPrice {
      value
    }
   
  }
 
}`;

export const priceQuery = (skip: number) => `{
  hourlyStats(skip: ${skip},first: 500, orderBy: timestamp, orderDirection: desc) {
    timestamp
    redemptionPrice{
      value
    }
    marketPriceUsd
  }
}`;
