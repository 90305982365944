import React from 'react';
import { AlertTriangle } from 'react-feather';
import styled from 'styled-components';
import GridContainer from '../../components/GridContainer';
import OutlineHeader from '../../components/OutlineHeader';
import Statistics from '../../components/Statistics';
import { useStoreState } from '../../store';

const StatisticsContainer = () => {
  const { statisticsModel: statisticsState } = useStoreState((state) => state);

  return (
    <>
      {statisticsState.loadingError ? (
        <Container>
          <Box>
            <AlertTriangle size="25" />
            <br />
            {statisticsState.loadingError}
          </Box>
        </Container>
      ) : (
        <GridContainer>
          <OutlineHeader
            outline="Protocol"
            text="Tokenomics"
            color={'blueish'}
          />
          <Statistics />
        </GridContainer>
      )}
    </>
  );
};

export default StatisticsContainer;

const Container = styled.div`
  min-height: 42vh;
`;

const Box = styled.div`
  padding: 30px;
  text-align: center;
  max-width: 300px;
  margin: 80px auto;
  background: ${(props) => props.theme.colors.foreground};
  border-radius: ${(props) => props.theme.global.borderRadius};
  color: ${(props) => props.theme.colors.neutral};
  line-height: 1.8;
  svg {
    color: ${(props) => props.theme.colors.yellowish};
  }
`;
