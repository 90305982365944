import React, { useEffect } from 'react';
import styled from 'styled-components';

// Redux
import { Info } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import useTokenomicsInfo from '../hooks/useTokenomics';
import { formatNumber } from '../utils/helper';
import Loader from './Loader';
import Status from './Status';
import commaNumber from 'comma-number';

dayjs.extend(relativeTime);

const Statistics = () => {
  const { stats, isLoading } = useTokenomicsInfo();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container>
      <div style={{ marginBottom: '20px' }}>
        <Status />
      </div>
      <Row className="col3">
        {stats.map((stat) => {
          return (
            <Col className={stat.width} key={stat.name}>
              <ColInner>
                {stat.tip ? (
                  <InfoIcon data-tip={stat.tip}>
                    <Info size="16" />
                  </InfoIcon>
                ) : null}

                <img src={stat.icon} alt={stat.icon} />
                <p>{stat.name}</p>
                <h3>
                  {!isLoading ? (
                    Number(stat.value) > 0 ? (
                      commaNumber(formatNumber(stat.value, 2))
                    ) : (
                      stat.value
                    )
                  ) : (
                    <Loader />
                  )}
                </h3>
              </ColInner>
            </Col>
          );
        })}
      </Row>

      <ReactTooltip multiline type="light" data-effect="solid" />
    </Container>
  );
};

export default Statistics;

const Container = styled.div`
  padding: 50px 15px;
`;
const Row = styled.div`
  display: flex;
  margin: 0 -10px;
  flex-wrap: wrap;

  p {
    color: ${(props) => props.theme.colors.customSecondary};
    font-size: 14px;
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
    color: ${(props) => props.theme.colors.neutral};
  }
  &.fullHeight {
    height: 100%;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const Col = styled.div`
  padding: 0 10px;

  &.col3 {
    &.normalBlocks {
      &.fixMargin {
        margin: 0px 0 20px 0;
        @media (max-width: 991px) {
          margin: 10px 0;
        }
      }
      text-align: center;
      p {
        width: 100% !important;
        margin-top: 0;
      }
      h4 {
        font-size: 1.1rem;
      }
    }

    margin: 20px 0 0;
    flex: 0 0 33.33%;
    text-align: center;

    > div {
      padding: 40px 10px;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    p {
      margin-top: 20px;
    }
    img {
      max-width: 50px;
    }
    h3 {
      font-size: 1.5rem;
      margin-bottom: 0;
      margin-top: 10px;
    }
    &.col3 {
      @media (max-width: 991px) {
        margin: 10px 0;
        .inner {
          padding: 20px 10px;
        }
      }
    }
  }

  &.col50 {
    flex: 0 0 50%;
    margin-top: 30px;
    &.normalBlocks {
      margin: 20px 0 20px 0;
      text-align: center;
      p {
        width: 100% !important;
      }
      > div {
        padding: 40px 10px;
      }
    }
    &.fullHeight {
      margin: 40px 0 20px 0;

      .greenish {
        background: ${(props) => props.theme.colors.greenish};
        p {
          color: ${(props) => props.theme.colors.background};
        }
        h4 {
          color: ${(props) => props.theme.colors.background};
        }
      }
      .inner {
        padding: 20px 30px 10px;
        background: #0b1226;
        height: 100%;
      }
      p {
        width: 100px;
        line-height: 1.5;
        @media (max-width: 991px) {
          width: 100%;
        }
      }
      h4 {
        font-size: 1.5rem;
        margin-bottom: 0;
        margin-top: 10px;
        font-family: 'Montserrat', sans-serif;
        color: ${(props) => props.theme.colors.neutral};
      }
    }
  }

  &.col50 {
    h3 {
      margin-top: 10px;
      margin-bottom: 0;
    }
    @media (max-width: 991px) {
      margin: 10px 0 !important;
      .inner {
        padding: 30px 10px 20px 10px !important;
      }
      &.fixMobile {
        margin-top: 60px !important;
      }
    }
  }
`;
const ColInner = styled.div`
  position: relative;
  padding: 50px 20px;
  background: ${(props) => props.theme.colors.foreground};
  border-radius: ${(props) => props.theme.global.borderRadius};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const InfoIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  svg {
    fill: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.yellowish};
  }
`;
