import { action, Action, thunk, Thunk } from 'easy-peasy';
import { IStats } from '../utils/interfaces';
import { fetchStatistics } from '../services/graphql';
import api from '../services/api';

export interface StatisticsModel {
  stats: IStats | null;
  loadingError: string;
  ethFiatPrice: number;
  listLength: number;
  flxAirdropAmount: string;
  fetchStatisticsData: Thunk<StatisticsModel>;
  fetchEthFiatPrice: Thunk<StatisticsModel>;
  fetchFlxAirdropAmount: Thunk<StatisticsModel>;
  setStats: Action<StatisticsModel, IStats>;
  setLoadingError: Action<StatisticsModel, string>;
  setEthFiatPrice: Action<StatisticsModel, number>;
  setListLength: Action<StatisticsModel, number>;
  setFlxAirdropAmount: Action<StatisticsModel, string>;
}

const statisticsModel: StatisticsModel = {
  stats: null,
  loadingError: '',
  ethFiatPrice: 0,
  listLength: 100,
  flxAirdropAmount: '',
  fetchStatisticsData: thunk(async (actions, payload) => {
    const stats = await fetchStatistics();
    actions.setStats(stats);
  }),
  fetchEthFiatPrice: thunk(async (actions) => {
    const fiatPrice = await api.fetchFiatPrice();
    actions.setEthFiatPrice(fiatPrice);
  }),
  fetchFlxAirdropAmount: thunk(async (actions) => {
    const flxAmount = await api.fetchFiatPrice();
    actions.setFlxAirdropAmount(flxAmount);
  }),
  setStats: action((state, payload) => {
    state.stats = payload;
  }),
  setLoadingError: action((state, payload) => {
    state.loadingError = payload;
  }),
  setEthFiatPrice: action((state, payload) => {
    state.ethFiatPrice = payload;
  }),
  setFlxAirdropAmount: action((state, payload) => {
    state.flxAirdropAmount = payload;
  }),
  setListLength: action((state, payload) => {
    state.listLength = payload;
  }),
};

export default statisticsModel;
