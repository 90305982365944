import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Loader from './Loader';
import useTokenomicsInfo from '../hooks/useTokenomics';
import { useStoreActions, useStoreState } from '../store';

const Status = () => {
  const { surplusBufferBar, isLoading } = useTokenomicsInfo();
  const [width, setWidth] = useState(window.innerWidth);
  const { statisticsModel: statisticsActions } = useStoreActions(
    (state) => state
  );
  const { statisticsModel: statisticsState } = useStoreState((state) => state);

  const { listLength } = statisticsState;

  const updateStatusListLength = () => {
    setWidth(window.innerWidth);
    switch (true) {
      case width < 400:
        statisticsActions.setListLength(12);
        break;
      case width < 600:
        statisticsActions.setListLength(24);
        break;
      case width < 850:
        statisticsActions.setListLength(48);
        break;
      case width < 1100:
        statisticsActions.setListLength(72);
        break;
      default:
        statisticsActions.setListLength(100);
    }
  };

  const buffer = useMemo(
    () => Math.ceil(Number(surplusBufferBar) * 100),
    [surplusBufferBar]
  );

  useEffect(() => {
    updateStatusListLength();
    window.addEventListener('resize', updateStatusListLength);
    return () => window.removeEventListener('resize', updateStatusListLength);
    // eslint-disable-next-line
  }, [width]);

  return (
    <>
      <StatusData>
        <StatusText>Surplus Buffer</StatusText>
        {isLoading ? null : <Tip>{buffer}%</Tip>}

        {isLoading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          <BarsContainer>
            <StatusBars>
              {Array.from({ length: listLength }).map((_key, i: number) => {
                return <BlockState key={i}></BlockState>;
              })}
            </StatusBars>
            <ReactTooltip />
          </BarsContainer>
        )}
      </StatusData>
    </>
  );
};

export default Status;

const StatusData = styled.div`
  background: ${(props) => props.theme.colors.foreground};
  border-radius: 5px;
  padding: 2.5rem 1.8rem;
  text-align: center;
  margin-top: 2rem;
  position: relative;
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StatusText = styled.p`
  text-align: center;
  margin-top: 0;
  color: ${(props) => props.theme.colors.customSecondary};
`;

const BarsContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 2rem;
`;

const StatusBars = styled.div``;

const BlockState = styled.div`
  width: 12px;
  height: 2rem;
  border-radius: 2px;
  background: ${(props) => props.theme.colors.greenish};
  display: inline-block;
  vertical-align: middle;
  margin-right: 1px;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
`;

const Tip = styled.div`
  width: fit-content;
  right: 4rem;
  top: 45px;
  color: white;
  position: absolute;
  display: flex;
  align-items: center;
  @media (max-width: 400px) {
    right: 40px;
    top: 82px;
  }
`;
