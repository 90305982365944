import React, { Suspense, useEffect } from 'react';
import i18next from 'i18next';
import { ThemeProvider } from 'styled-components';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import ErrorBoundary from './ErrorBoundary';
import { useStoreActions, useStoreState } from './store';
import { darkTheme } from './utils/themes/dark';
import { Theme } from './utils/interfaces';
import { initI18n } from './utils/i18n';
import GlobalStyle from './GlobalStyle';
import Shared from './containers/Shared';
import PrivacyContainer from './containers/Privacy';
import StatisticsContainer from './containers/Statistics';
import LoadingModal from './components/Modals/LoadingModal';

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}

const App = () => {
  const { settingsModel: settingsState, popupsModel: popupsState } =
    useStoreState((state) => state);
  const { popupsModel: popupsActions, statisticsModel: statisticsActions } =
    useStoreActions((actions) => actions);

  const { lang, bodyOverflow } = settingsState;

  useEffect(() => {
    initI18n(lang);
  }, [lang]);

  const hideLoader = () => {
    setTimeout(() => {
      popupsActions.setShowData(true);
      popupsActions.setIsLoadingModalOpen({
        isOpen: false,
        text: '',
      });
    }, 500);
  };

  const fetchStatistics = async () => {
    try {
      await statisticsActions.fetchStatisticsData();
    } catch (e) {
      // eslint-disable-next-line
      if (e && e instanceof Error && e.message === 'CONTRACT_LIST_ERROR') {
        statisticsActions.setLoadingError(
          'Loading subgraph data failed due to a failure loading contract list!'
        );
      } else {
        statisticsActions.setLoadingError(
          'Failed loading subgraph data, node might be down or something went wrong!'
        );
      }
    }

    hideLoader();
  };

  useEffect(() => {
    statisticsActions.fetchEthFiatPrice();
    if (window.location.pathname !== '/privacy') {
      fetchStatistics();
    } else {
      hideLoader();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <I18nextProvider i18n={i18next}>
      <ThemeProvider theme={darkTheme}>
        <GlobalStyle bodyOverflow={bodyOverflow} />
        <Router>
          <ErrorBoundary>
            <Suspense fallback={null}>
              <LoadingModal />
              {popupsState.showData ? (
                <Shared>
                  <Switch>
                    <Route exact component={StatisticsContainer} path={'/'} />
                    <Route
                      exact
                      component={PrivacyContainer}
                      path={'/privacy'}
                    />
                    <Redirect from="*" to="/" />
                  </Switch>
                </Shared>
              ) : null}
            </Suspense>
          </ErrorBoundary>
        </Router>
      </ThemeProvider>
    </I18nextProvider>
  );
};

export default App;
